/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import { makeStyles } from '@material-ui/core/styles';
import { object, bool, string } from 'prop-types';
import React from 'react';
import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles(() => ({
    holidayBannerRoot: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '12px 10px',
        margin: '10px 0px ',
        borderRadius: '6px',
        width: '350px',
        height: '50px',
        '@media(min-width: 600px)': {
            margin: '10px 50px 0px',
            width: '490px',
            height: '50px',

        },
        '@media(max-width: 375px)': {
            width: '320px',
        },
    },
    weatherBannerRoot: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '12px 5px',
        margin: '10px 0px',
        borderRadius: '6px',
        width: '350px',
        height: '71px',
        '@media(min-width: 600px)': {
            margin: '10px 50px',
            width: '490px',
            height: '83px',

        },
        '@media(max-width: 400px)': {
            margin: '10px -10px',
        },
        '@media(max-width: 360px)': {
            margin: '10px -20px',
        },
    },
    heading: {
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '20px',

        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        color: '#000000',
        '& span': {
            display: 'flex',
        },
    },
    messageBody: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        color: '#000000',
        display: 'flex',
    },
    copy: {
        textAlign: 'center',
        color: '#000000',
        '& p': {
            margin: 0,
            '@media(min-width: 600px)': {
                width: '370px',
            },
            '@media(max-width: 375px)': {
                fontSize: '12px',
            },
        },
    },
    iconLeft: {
        marginRight: '6px',
    },
    iconRight: {
        marginLeft: '6px',
    },
    holidayIconLeft: {
        marginRight: '6px',
    },
    holidayIconRight: {
        marginLeft: '6px',
    },
}));

const CalendarBanner = ({
    data, weatherBanner, deliveryType, featureFlags,
}) => {
    const classes = useStyles();
    let bannerTest = {};
    if (!weatherBanner && featureFlags['experiment-calendar-banner']?.type === 'variant') {
        bannerTest = featureFlags['experiment-calendar-banner'];
        localStorage.setItem('holidayBanner', true);
    } else if (!weatherBanner && featureFlags['experiment-calendar-banner']?.type !== 'variant') {
        localStorage.removeItem('holidayBanner');
        return <></>;
    }

    // there is css implemented(maybe in CS) conflicting with div, using section for now.
    return (
        // To avoid memory leak adding dynamic color here
        <section style={{ background: data?.banner?.background_color?.color || data?.banner?.background?.color  || 'transparent' }} className={weatherBanner ? classes.weatherBannerRoot : classes.holidayBannerRoot}>
            {weatherBanner && data?.title && (
                <section className={classes.heading}>
                    <span className={classes.iconLeft}>
                        <img src="https://images.contentstack.io/v3/assets/bltdd99f24e8a94d536/blta7646be5544c7f49/63c15a145775627a4a46bbfd/weatherIcon.svg" alt="weather_banner_left" />
                    </span>
                    {data?.title}
                    <span className={classes.iconRight}>
                        <img src="https://images.contentstack.io/v3/assets/bltdd99f24e8a94d536/blta7646be5544c7f49/63c15a145775627a4a46bbfd/weatherIcon.svg" alt="weather_banner_right" />
                    </span>
                </section>
            )}
            <section className={classes.messageBody}>
                {bannerTest?.icon && (
                    <span className={classes.holidayIconLeft}>
                        <img src={bannerTest?.icon} alt="holiday_banner_left" />
                    </span>
                )}
                <ReactMarkdown className={classes.copy} source={bannerTest?.copy?.[deliveryType] || data?.banner?.copy} escapeHtml={false} />
                {bannerTest?.icon && (
                    <span className={classes.holidayIconRight}>
                        <img src={bannerTest?.icon} alt="holiday_banner_right" />
                    </span>
                )}
            </section>
        </section>
    );
};

CalendarBanner.propTypes = {
    data: object,
    weatherBanner: bool,
    deliveryType: string,
    featureFlags: object,
};

CalendarBanner.defaultProps = {
    data: null,
    weatherBanner: false,
    deliveryType: '',
    featureFlags: {},
};

export default CalendarBanner;
